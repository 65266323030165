@import url("https://fonts.googleapis.com/css?family=Raleway:200");

$BaseBG: #ffffff;

.container {
  width: 100%;
  // height: 50vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container .title {
  text-align: center;
  margin-top: 80px;
  margin-bottom: 50px;
  padding: 20px;
}

.container .title h3 {
  font-weight: 500;
  font-size: 30px;
  line-height: 30px;
  text-align: center;
  color: #61481c;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  margin-bottom: 50px;
}

.container .title p {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 25px;
  text-align: center;
  color: #61481c;
}

.digit-group input {
  // width: 60px;
  // height: 60px;
  // background-color: lighten($BaseBG, 5%);
  // border: none;
  // line-height: 50px;
  // text-align: center;
  // font-size: 62px;
  // font-family: "Raleway", sans-serif;
  // font-weight: 800;
  // color: black;
  // margin: 2px 4px;

  // ::-webkit-input-placeholder {
  //   /* Edge */
  //   font-weight: 800;
  //   color: #9c9a9a;
  // }

  // :-ms-input-placeholder {
  //   /* Internet Explorer */
  //   font-weight: 800;
  //   color: #9c9a9a;
  // }

  // ::placeholder {
  //   font-weight: 900;
  //   color: #61481c;
  //   font-size: 100px;
  // }

  border: none;
  border-bottom: 4px solid #61481c;
  line-height: 1;
  text-align: center;
  font-size: 60px;
  margin: 0 30px 30px;
  width: 60px;

  &:focus {
    outline: none;
  }
}

.prompt {
  margin-bottom: 50px;
  font-size: 20px;
  color: white;
}

.container .otp-expire {
  // margin-left: 250px;
  text-align: right;
  margin-top: 30px;
  margin-bottom: 80px;
  margin-right: 30px;
}

.container .submitBtn {
  padding: 20px;
  margin-top: 200px;
}

.container .submitBtn a {
  background: #e6b325;
  padding: 0.5em 5em;
  border-radius: 5px;

  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 15px;
  color: #61481c;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
}

.custom-btn {
  background: #e6b325;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  border-radius: 5px;
  color: #61481c;
  font-size: 16px;
  font-weight: 300;
  padding: 6px 70px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: #a47e3b;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #ffffff;
  }
}

.custom-lottie {
  p {
    font-weight: 400;
  }

  svg {
    width: 300px !important;
  }
}
