.delivery-content-container .right-header {
  padding: 20px;
  padding-left: 10px;
}

.delivery-content-container .right .right-header h3 {
  margin-left: 20px;
}

.search {
  position: relative;
  margin-left: 20px;

  > div {
    width: 50%;
  }
}

.search input {
  background: #f3f3f3;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
  height: 40px;
  width: 100%;
  margin: 0;
  padding: 12px 40px 12px 12px;
  line-height: 1;
  outline: none;

  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 12px;
  color: #7d7d7c;
}

.search img {
  width: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.delivery-content-container .right form {
  left: 0px;
  top: 0px;
}

.delivery-content-container .right textarea {
  resize: none;
  width: 778px;
  height: 121px;
  top: 21px;
  radius: 10px;
  background: #dddcdc;
  border: 0.5px solid #61481c;
  border-radius: 10px;
}

.delivery-content-container .right .restoInfo1 {
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  justify-content: left;
  margin-left: 74px;
  padding: 5px;
}

.delivery-content-container .right .restoInfo1 label {
  font-size: 15px;
}

.delivery-content-container .right .restoInfo1 input {
  background: #dddcdc;
  border: 0.5px solid #61481c;
  border-radius: 10px;
  width: 300px;
  padding: 1em 2em;
}

.delivery-content-container .right .restoInfo1 .restoInfo1-div {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.delivery-content-container .right .restoInfo2 {
  display: grid;
  grid-template-columns: auto auto;
  width: 90%;
  padding: 20px;
  margin-left: 58px;
  gap: 5px;
}

.delivery-content-container .right .restoInfo2 label {
  font-size: 15px;
}

.delivery-content-container .right .restoInfo2 input {
  background: #dddcdc;
  border: 0.5px solid #61481c;
  border-radius: 10px;
  width: 300px;
  padding: 1em 2em;
}

.delivery-content-container .right .restoInfo2 .restoInfo2-div {
  display: flex;
  flex-direction: column;
}

.delivery-content-container .right .restoInfo1 input::placeholder {
  color: black;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
}

.delivery-content-container .right .restoInfo1 textarea::placeholder {
  color: black;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  padding: 1em;
}

.delivery-content-container .right .restoInfo2 input::placeholder {
  color: black;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
}

.delivery-content-container .right .buttons {
  float: right;
  margin-top: 20px;
  margin-right: 80px;
  display: flex;
  gap: 20px;

  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
}

.delivery-content-container .right .buttons #editBtn {
  background: #d9d9d9;
  padding: 1em 5em;
  color: black;

  border: 0.5px solid rgba(97, 72, 28, 0.5);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  text-decoration: none;
}

.delivery-content-container .right .buttons #saveBtn {
  background: #d9d9d9;
  padding: 1em 5em;
  color: black;

  border: 0.5px solid rgba(97, 72, 28, 0.5);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  text-decoration: none;
}

.bike-images {
  display: flex;
  gap: 15px;
  margin-top: 25px;
}

.bike-images img {
  width: 80px;
}

.right .title {
  position: relative;
}

.right .title .title-list {
  display: flex;
  /* justify-content: center;
    gap: 60px; */
  justify-content: space-evenly;
  align-items: center;
  margin-top: 0px;
  padding: 0.7em 1em;
  background: #61481c;
  border: 1px solid #61481c;

  color: white;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
}

.right .title .menu-list {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
  background: #fde9b1;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;

  font-style: normal;
  font-weight: 100;
  font-size: 14px;
  line-height: 23px;
}

/* .menu-list li {
    margin-left: 60px;
    display: flex;
    align-items: center;
  } */

.right li {
  list-style: none;
}

.menu-list .buttons {
}

.menu-list .buttons button {
  font-weight: 300;
  font-size: 7px;
  line-height: 10px;
  margin-top: -20px;
  color: #e6b325;
  text-shadow: 1px 1px 1px rgba(97, 72, 28, 0.25);
  text-align: center;

  background: black;
  padding: 1em 2em;
  border: 0.5px solid #61481c;
  border-radius: 3px;
}

/* .switch {
    position: relative;
    display: inline-block;
    width: 43px;
    height: 23px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  input:checked + .slider {
    background-color: #2196f3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(20px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  } */
