.reset-password-container {
  .button-container {
    width: 359px;
    height: 766px;
    background: #f2f2f2;
    border: 1px solid #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 40px;

    a {
      background: #dddcdc;
      padding: 1em 2em;
      border: 0.5px solid #61481c;
      border-radius: 10px;
      text-decoration: none;
      color: black;
      font-weight: 300;
      font-size: 18px;
      cursor: pointer;
      width: 300px;
      text-align: center;
    }
  }

  .resetpw-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    gap: 40px;
    width: 100%;
    height: 766px;

    .w-hide-icon {
      position: relative;

      img {
        position: absolute;
        top: 12px;
        right: 10px;
      }
    }

    input {
      width: 384px;
      height: 55px;
      left: 630px;
      top: 239px;

      border: 1px solid #000000;
      border-radius: 10px;
      padding: 20px;

      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
    }

    h3 {
      font-weight: 600;
      font-size: 25px;
      line-height: 30px;
    }

    a {
      background: #61481c;
      padding: 1em 5.2em;
      border-radius: 10px;

      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
      color: white;
    }

    img {
      width: 30px;
    }
  }
}
