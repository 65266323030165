.profile-content-container {
  .right h3 {
    padding: 25px;
  }

  .right form {
    left: 0px;
    top: 0px;
  }

  .right textarea {
    resize: none;
    top: 21px;
    radius: 10px;
    background: #dddcdc;
    border: 0.5px solid #61481c;
    border-radius: 10px;
  }

  .right .restoInfo1 {
    display: grid;
    grid-template-columns: auto;
    align-items: center;
    justify-content: left;
    margin-left: 74px;
    padding: 5px;
  }

  .right .restoInfo1 label {
    font-size: 15px;
  }

  .right .restoInfo1 input {
    background: #dddcdc;
    border: 0.5px solid #61481c;
    border-radius: 10px;
    width: 300px;
    padding: 1em 2em;
  }

  .right .restoInfo1 .restoInfo1-div {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .right .restoInfo2 {
    display: grid;
    grid-template-columns: auto auto;
    width: 90%;
    padding: 20px;
    margin-left: 58px;
    gap: 5px;
  }

  .right .restoInfo2 label {
    font-size: 15px;
  }

  .right .restoInfo2 input {
    background: #dddcdc;
    border: 0.5px solid #61481c;
    border-radius: 10px;
    width: 300px;
    padding: 1em 2em;
  }

  .right .restoInfo2 .restoInfo2-div {
    display: flex;
    flex-direction: column;
  }

  .right .restoInfo1 input::placeholder {
    color: black;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
  }

  .right .restoInfo1 textarea::placeholder {
    color: black;
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    padding: 1em;
  }

  .right .restoInfo2 input::placeholder {
    color: black;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
  }

  .right .buttons {
    float: right;
    margin-top: 20px;
    margin-right: 80px;
    display: flex;
    gap: 20px;

    text-transform: uppercase;
    cursor: pointer;
    font-size: 12px;
  }

  .right .buttons #editBtn {
    background: #d9d9d9;
    padding: 1em 5em;
    color: black;

    border: 0.5px solid rgba(97, 72, 28, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    text-decoration: none;
  }

  .right .buttons #saveBtn {
    background: #d9d9d9;
    padding: 1em 5em;
    color: black;

    border: 0.5px solid rgba(97, 72, 28, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    text-decoration: none;
  }

  .bike-images {
    display: flex;
    gap: 15px;
    margin-top: 25px;
  }

  .bike-images img {
    width: 80px;
  }
}
